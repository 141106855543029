document.addEventListener('DOMContentLoaded', function() {
    const button = document.querySelector('.menu-button');
    const header = document.querySelector('.header');

    button.addEventListener('click', function() {
        header.classList.toggle('active');
        button.classList.toggle('active');

        if (header.classList.contains('active')) {
            button.textContent = 'Lukk';
        } else {
            button.textContent = 'Meny';
        }
    });
});