import "@splidejs/splide/css";
import Splide from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", function () {
	const horisontals = [...document.querySelectorAll(".splide-horisontal")];
	horisontals.forEach((horisontal) => {
		new Splide(horisontal, {
			pagination: true,
			arrows: false,
			autoWidth: true,
			breakpoints: {
				1025: {
					destroy: true,
				},
			},
		}).mount();
	});

	const verticals = [...document.querySelectorAll(".splide-vertical")];

	verticals.forEach((vertical) => {
		new Splide(vertical, {
			direction: "ttb",
			width: true,
			height: 700,
			pagination: true,
			arrows: false,
			breakpoints: {
				1025: {
					destroy: true,
				},
			},
		}).mount();
	});
});
