document.querySelectorAll('.gallery').forEach(gallery => {
    gallery.addEventListener('click', function(event) {
        if (event.target.tagName === 'IMG' || event.target.classList.contains('gallery-image')) {
            let targetElement = event.target.tagName === 'IMG' ? event.target.parentElement : event.target;

            gallery.appendChild(targetElement);

            const figures = gallery.querySelectorAll('.gallery-image');
            figures.forEach((fig, index) => {

                const figcaption = fig.querySelector('figcaption');
                if (figcaption) {
                    figcaption.style.visibility = 'hidden';
                    figcaption.style.opacity = '0';
                }
            });

            const frontFigcaption = figures[0].querySelector('figcaption');
            if (frontFigcaption) {
                frontFigcaption.style.visibility = 'visible';
                frontFigcaption.style.opacity = '1';
            }
        }
    });
});