import "@/styles/index.scss";

import '@/app/components/Splide';
import '@/app/components/Gallery';
import '@/app/components/MenyButton';

class App {
	_onResize() {
		if (this.webGL) {
			this.webGL.onResize();
		}
	}

	_addEventListeners() {
		window.addEventListener("resize", this._onResize.bind(this));
		window.addEventListener("mousemove", this._onMouseMove.bind(this));
	}
}

new App();

console.log("%c Developed by ANTI - https://anti.as/", "background: #000; color: #fff;");
